@import "../../global.scss";

.navbar {
  width: 100%;
  height: 85px;
  background-color: white;
  color: $steelblue;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;
  // overflow: hidden; 




  .wrapper {
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      // width: 50%;
      // justify-content: space-between;

      .logo {
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
        // margin-left: 40px;
      }

      .terminalIcon {
        font-size: 30px;
        margin-left: 8px;
        margin-right: 6px;
      }

      .itemContainer {
        display: flex;
        align-items: center;
        padding: 2.5px;
        margin-right: 25px;

        @include mobile{
          display: none;
        }

        .icon {
          font-size: 20px;
          margin-right: 5px;
        }

        span {
          font-size: 20px;
          font-weight: 500;

          a {
            color: $steelblue;
            text-decoration: none;
          }
        }

        &:hover {
          font-size: 22px;
          border-radius: 5px;
          box-shadow: 0px 0px 10px $steelblue;
          transition: box-shadow 1s ease-in-out;
          span, a, .icon {
            
            font-size: 23px;
            transition: font-size .5s ease-in-out;
          }
        }
      }
    }

    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: $steelblue;
          transform-origin: left;
          transition: all 2s ease;
        }
      }
    }
  }

  &.active {
    background-color: $steelblue;
    color: white;

    .itemContainer, .icon {
      display: none;
    }
    .hamburger {

      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        } 

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
  &:active {
    a {
      color: $steelblue;
    }
  }
}