@import '../../global.scss';

.portfolioList {
  font-style: 14px;
  margin-right: 15px;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;

  &.active {
    background-color: $lightpurple;
    color: white;
  }
}