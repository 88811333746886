@import '../../global.scss';

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $beige;
  color: white;

  @include mobile {
    padding-top: 50px;
  }
  
  .container {
    width: 45%;

    @include mobile {
      width: 90%;
    }

    h1 {
      font-size: 70px;
      margin-bottom: 15px;

      @include mobile {
        font-size: 40px;
      }
    }

    p {
      font-size: 25px;
      padding: 10px; 

      @include mobile {
        font-size: 20px;
      }
    }
    hr {
      overflow: visible;
      height: 30px;
      border: white solid;
      border-width: 1px 0 0 0;
      border-radius: 20px;
      width: 90%;
    }
    
    hr:before {
      display: block;
      content: "";
      height: 30px;
      margin-top: -31px;
      border: white solid;
      border-width: 0 0 1px 0;
      border-radius: 20px;
    }
  }
}