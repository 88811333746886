@import '../../global.scss';

.skills {
  background-color: $darkpurple;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-direction: column;
  text-align: center;

  @include mobile {
    width: 90%;
    overflow: hidden;
  }
  
  .text {
    width: 45%;
    margin-top: 0;
    padding-top: 0;
    margin: 40px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h1 {
      font-size: 70px;
      margin-bottom: 15px;
    }
    
    p {
      font-size: 25px;
    }

    @include mobile {
      width: 100%;
  
      h1 {
        font-size: 40px;
      }
  
      p {
        font-size: 20px;
      }
    }
    hr {
      overflow: visible;
      height: 30px;
      border: white solid;
      border-width: 1px 0 0 0;
      border-radius: 20px;
      width: 90%;
    }
    
    hr:before {
      display: block;
      content: "";
      height: 30px;
      margin-top: -31px;
      border: white solid;
      border-width: 0 0 1px 0;
      border-radius: 20px;
    }
  }
    
    .iconContainer {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    @include mobile {
      width: 90%;
      font-size: 40px;
    }

    i {
      font-size: 100px;
      margin: 10px;
      padding-bottom: 0;

      @include mobile {
        font-size: 50px;
      }

      &:hover {
        font-size: 120px;
        animation: font-size 5s ease-in-out;

        @include mobile {
          font-size: 65px;
        }
      }
    }
  }
 
}
