@import '../../global.scss';

.intro {
  background-color: $lightpurple;
  display: flex;
  text-align: center;

  @include mobile {
    flex-direction: column;
    align-items: center;


  }

  .left {
    flex: 0.5;
    overflow: hidden;

    .imgContainer {
      width: 700px;
      height: 700px;
      background-color: $darkpurple;
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: right;
      margin-top: 75px;

      @include mobile {
        align-items: flex-start;
        margin-top: 30px;
      }

      img {
        height: 90%;

        @include mobile {
          height: 60%;
        }
      }
    }
  }
  
  .right {
    flex: 0.5;
    color: $darkpurple;
    position: relative;
    overflow: hidden;
    
    .wrapper {
      width: 70%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 25px;


      @include mobile {
        align-items: center;
        overflow: hidden;
        margin-bottom: 15px;
      }

      h1 {
        font-size: 90px;
        // margin: 10px  0;
        color: white;

        @include mobile {
          font-size: 35px;
          overflow: hidden;
          padding-bottom: 5px;
        }
      }

      h2 {
        font-size: 35px;

        @include mobile {
          padding-top: 20px;
          font-size: 25px;
          overflow: hidden;
          padding-bottom: 5px;
        }
      }
        h3 { 
          font-size: 45px;
          padding: 10px;
          padding-left: 0;

          @include mobile {
            font-size: 30px;
            overflow: hidden; 
          }

          span {
            font-size: inherit;
            color: white;
            
          }
          
          .ityped-cursor {
            animation: blink 1s infinite;
            
          }
          @keyframes blink {
            50% {
              opacity: 1;
            }
            100%{
              opacity: 0;
            }
          }
        }

      p {
        font-weight: bold;
        padding: 5px;
        text-align: center;

        @include mobile {
          display: flex;
          font-size: 20px;
        }
      }
      
      a {
        position: absolute;
        bottom: 10px;
        
        img {
          animation: arrowBlink 2s infinite;

        }
        @keyframes arrowBlink {
          100% {
            opacity: 0;
          }
        }
      }
    
    }
  }
}