@import '../../global.scss';

.portfolio {
  background-color: $steelblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h1 {
    font-size: 50px;
  }
  
  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
  }
  .container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // border: yellow 1px solid;
    
    .item {
      width: 220px;
      height: 150px;
      border: 1px solid lightgrey;
      border-radius: 20px;
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;

      h3 {
        position: absolute;
        font-size: 20px;
      }
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }
      &:hover {
        background-color: $lightpurple;
        
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}