// * {
//   border: gold 1px solid;
// }
.app {
  height: 100vh;

  .sections {
    width: 100%;
    height: calc(100vh - 80px);
    // background-color: lightsalmon;
    position: relative;
    top: 80px;
    scroll-behavior: smooth;
    // scroll-snap-type: y mandatory;
    scrollbar-width: none;
    transition: all 5s ease;

    &::-webkit-scrollbar {
      display: none;
      scroll-behavior: smooth;
      scroll-snap-type: y mandatory;
      scrollbar-width: none;
    }

    > * {
      width: 100vw;
      height: calc(100vh - 80px);
      scroll-snap-align: start;
    }
  }
}
